<template>
  <div class="animated fadeIn">
    <div id="snackbar"></div>
    <b-row>
      <b-col sm="12">
        <b-card>
          <b-card-header>
            <i class="icon-menu mr-1"></i>List of Event Manager
            <div class="card-header-actions"></div>
          </b-card-header>

          <b-card-body>
            <v-client-table
              :columns="columns"
              :data="data"
              :options="options"
              :theme="theme"
              id="dataTable"
            >
              <div slot="ID" slot-scope="props" class="update-center">
                <i class="fa fa-edit" @click="editBanner(props.row.event_manager_id)"></i>
              </div>

            </v-client-table>
          </b-card-body>
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import Vue from "vue";
import Sortable from "sortablejs";
import { Switch as cSwitch } from "@coreui/vue";
import MasterService from "@/services/MasterService";
import draggable from "vuedraggable";

Vue.directive("sortable", {
  inserted: function (el, binding) {
    var sortable = new Sortable(el, binding.value || {});
  },
});
export default {
  data() {
    return {
      columns: [
        "event_manager_id",
        "em_name",
        "em_email",
        "ID"
      ],
      data: [],
      options: {
        headings: {
          event_manager_id: "Event Id",
          em_name: "Event Name",
          em_email: "Email",
          ID: "Update"       
        },
        sortable: ["event_manager_id"],
        filterable: ["em_name"],
        sortIcon: {
          base: "fa",
          up: "fa-sort-asc",
          down: "fa-sort-desc",
          is: "fa-sort"
        },
        perPage: 10,
        pagination: {
          chunk: 5,
          edge: false,
          nav: "scroll"
        }
      },
      useVuex: false,
      theme: "bootstrap4",
      template: "default",
      labelIcon: {
        dataOn: "\u2713",
        dataOff: "\u2715"
      },
      eventManagerList: [],
      searchData: "",
      // SetLabelIcon
      labelIcon: {
        dataOn: "\u2713",
        dataOff: "\u2715",
      },
    };
  },
  components: {
    cSwitch,
    draggable,
  },
  computed: {},
  watch: {},
  beforeMount() {
    this.getEventManagerList();
  },
  mounted: function () {},
  methods: {
    
    getEventManagerList: function() {
        MasterService.getEventManagerList()
        .then((response) => {
            const { data } = response;
          if (data.data.length > 0) {
            this.data = response.data.data;
          } else {
            this.showMessageSnackbar(data.Message);
          }
        })
        .catch((error)=> {
            console.log("Catch on Events Category Error ", error);
            this.showMessageSnackbar(
            "Oops! Something went wrong. kindly contact your administrator"
          );
        });
    },

    editBanner: function (event_manager_id) {
        if(event_manager_id){
            this.$router.push({
                path:'/events/addeventmanager',
                query:{event_manager_id}
            })
        }
    },
    changeStatus: function (ID) {
      let payload = {
        TableName: "BannerImage",
        ID,
      };
      MasterService.activateOrDeactivate(payload)
        .then((response) => {
          const { data } = response;
          data.Message ? this.showMessageSnackbar(data.Message) : "";
        })
        .catch((error) => {
          this.showMessageSnackbar(
            "Oops! Something went wrong. kindly contact your administrator"
          );
        });
    },
    // Show Message
    showMessageSnackbar: function (message) {
      let x = document.getElementById("snackbar");
      x.className = "show";
      x.innerHTML = message;
      setTimeout(function () {
        x.className = x.className.replace("show", "");
      }, 3000);
    },
  },
  updated() {},
};
</script>

<style>
.fa-edit {
  cursor: pointer;
  font-size: 18px;
}
.fa-edit:hover {
  color: #20a8d8;
}
.banner-list li {
  padding: 20px;
  list-style-type: none;
  cursor: pointer;
  background: #fff;
  border-bottom: 1px solid #eee;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.banner-list li .row .col-2,
.col-3 {
  text-align: center;
}
.update-center {
  text-align: center;
}
</style>
